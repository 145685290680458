import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

const Useauth = () => {
    const token = localStorage.getItem('Token');
    console.log(token, "ggcjgjcgyu");
    return token !== null;

}

const Auth = () => {
    const isAuth = Useauth();

    return isAuth ? <Outlet /> : <Navigate to="/" />;
}

export default Auth;