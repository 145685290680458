import React, { useEffect, useState } from 'react';
import "./Admin.css";
import { IoIosArrowRoundBack } from 'react-icons/io';
import ReactPaginate from 'react-paginate';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdOutlineFileDownload } from "react-icons/md";
import { FaFilter, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getOrder, OrderFilter, OrdersFilter, OrdersService } from '../../Services/APIrequests';
import { warningSwal } from '../../Util/Toast';
import moment from 'moment/moment';
import Loader from '../../Util/Loader';
import Modal from 'react-modal';
import style from '../../Components/Modal/style.module.css';

function Orders() {
    const [itemOffset, setItemOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [status, setStatus] = useState(null);
    const [filterOpen, setFilterOpen] = useState(false);
    const Navigate = useNavigate();
    const [orderOpen, setOrderOpen] = useState(false);
    const [pagination, setPagination] = useState({});
    const [orderDetails, setOrderDetails] = useState([]);
    const dataPerPage = 9;

    const fetchData = async (page) => {
        setLoading(true);
        try {
            const res = await OrdersService(page, dataPerPage)
            setTimeout(() => {
                setLoading(false);
            }, 1500)
            if (res?.data?.Status === 1) {
                setData(res?.data?.Orders);
                setPagination({
                    page: page,
                    size: dataPerPage,
                    totalCount: res?.data?.Pagination.totalCount
                });
            } else {
                warningSwal("error", res?.data?.Message);
            }
        } catch (err) {
            console.log(err);
            // setLoading(false);

        }
    }

    const FilterData = async (page) => {
        if (status) {
            setLoading(true);
            try {
                const res = await OrdersFilter(page, dataPerPage, status);
                if (res?.data?.Status === 1) {
                    setData(res?.data?.Orders);
                    setPagination({
                        page: page,
                        size: dataPerPage,
                        totalCount: res?.data?.Pagination.totalCount
                    });
                } else {
                    warningSwal("error", res?.data?.Message);
                }
            } catch (err) {
                warningSwal("Error", err?.Response?.data?.Message);
                console.log(err);
            } finally {
                setLoading(false);
                setFilterOpen(false);
            }
        } else {
            warningSwal("warning", "Please select a order Status");
        }
    };

    useEffect(() => {
        fetchData(1);
    }, [])

    const Registered_blood_bank = () => {
        Navigate("/dashboard");
    }

    const pageCount = Math.ceil(pagination.totalCount / dataPerPage);
    const handlePageClick = (event) => {
        const newPage = event.selected + 1;
        setItemOffset((newPage - 1) * dataPerPage);
        setPagination((prevState) => ({ ...prevState, page: newPage }));
        if (status) {
            FilterData(newPage);
        } else {
            fetchData(newPage);
        }
        window.scrollTo({ top: 120, behavior: "smooth" });
    };

    const handleFilterSubmit = (event) => {
        event.preventDefault();
        FilterData(1);
    };

    const OrderDetails = async (id) => {
        setOrderOpen(true);
        try {
            const res = await getOrder(id);
            if (res?.data?.Status === 1) {
                setOrderDetails(res?.data?.Items);
            }
        } catch (err) {
            console.log(err)
            warningSwal("Error", err?.response?.data?.Message);
        }
    }

    const closeModal = () => {
        setOrderOpen(false);
        setOrderDetails([]);
    }

    return (
        <div className="dashboard">
            <div className="dashboard_admin_tab">
                <p className='dashboard_admin_heading'>Admin Portal</p>
            </div>
            <div className='Registered_blood_bank'>
                <div className="Registered_blood_header">
                    <div className='Resistered_blood_header_text'><IoIosArrowRoundBack onClick={() => Registered_blood_bank()} className='registered-icon' />Orders</div>
                    <div className='Resistered_blood_header_button'><button className='registered-filter-button' onClick={() => setFilterOpen(true)}><FaFilter />Filter</button></div>
                </div>
                {
                    loading ? <Loader /> :

                        <div className='registered_blood_bank_container'>
                            <table>
                                <thead className='registerd-header-items'>
                                    <tr>
                                        <th>Patient Name</th>
                                        <th>Blood Group</th>
                                        <th>Blood Component</th>
                                        <th>Order Channel</th>
                                        <th>Payment Type</th>
                                        <th>Payment Status</th>
                                        <th>Ordered Items</th>
                                        <th>Status</th>
                                        <th>Order date</th>
                                        {/* <th>Actions</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length === 0 ? <tr>
                                        <td colSpan={9}>No Orders here</td>
                                    </tr> :
                                        data.map((item) => (
                                            <tr key={item.orderId} onClick={() => OrderDetails(item.orderId)}>
                                                <td>{item.patientName}</td>
                                                <td>  Blood Type : {item.bloodGroup}</td>
                                                <td>{item.bloodComponent}</td>
                                                <td>{item.orderChannel === 0 ? "POS" : "ONLINE"}</td>
                                                <td>{item.orderStatus === 0 ?
                                                    <div
                                                        className={
                                                            "status-shipped"
                                                        }>
                                                        <span>Cash</span>
                                                    </div> : <div
                                                        className={
                                                            "status-delivered"
                                                        }>
                                                        <span>Online</span>
                                                    </div>
                                                }</td>
                                                <td>{item.paymentStatus === 0 ? "Pending" :
                                                    item.paymentStatus === 1 ? "Completed" : null}</td>
                                                <td>{item.quantity}{" "} Items</td>
                                                <td className={`order_status_button`}>
                                                    {item.orderStatus === 0 ?
                                                        <div
                                                            className={
                                                                "status-booked"
                                                            }>
                                                            <span>Booked</span>
                                                        </div>
                                                        :
                                                        item.orderStatus === 1 ?
                                                            <div
                                                                className={
                                                                    "status-issued"
                                                                }>
                                                                <span>Issued</span>
                                                            </div>
                                                            :
                                                            item.orderStatus === 2 ?
                                                                <div
                                                                    className={

                                                                        "status-shipped"
                                                                    }>
                                                                    <span>Shipped</span>
                                                                </div> :
                                                                item.orderStatus === 3 ?
                                                                    <div
                                                                        className={
                                                                            "status-delivered"
                                                                        }>
                                                                        <span>Delivered</span>
                                                                    </div>
                                                                    : null
                                                    }</td>
                                                <td className="registerd_contact">{moment(item.orderPlacedOn).format("Do MMM YYYY")}</td>
                                                {/* <td>
                                                    <span><MdOutlineFileDownload className='regsiterd_delete_icon' /></span>
                                                </td> */}
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="<"
                                forcePage={pagination.page - 1}
                                renderOnZeroPageCount={null}
                                pageClassName={"pageClassName"}
                                disabledLinkClassName={"disabledLinkClassName"}
                                disabledClassName={"paginationDisabled"}
                                containerClassName={"paginationButtons"}
                                nextClassName={"nextButton"}
                                previousClassName={"previousButton"}
                                activeClassName={"activeClassName"}
                                activeLinkClassName={"activeLinkClassName"}
                                pageLinkClassName={"pageLinkClassName"}
                            />
                        </div>
                }
            </div>
            <Modal
                isOpen={filterOpen}
                className="modal_class"
                overlayClassName="modal_overlay"
                onRequestClose={() => setFilterOpen(false)}
            >
                <div className='hedaer_group'>
                    <h2 className="modal_header">Orders Filter</h2>
                    <button onClick={() => setFilterOpen(false)} className="close-btn"><FaTimes size={20} /></button>
                </div>
                <form className="filters" onSubmit={handleFilterSubmit}>
                    <div className="form-control">
                        <select
                            id="orderStatus"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <option value="">Select Order Status</option>
                            <option value={0}> Booked </option>
                            <option value={1}> Issued </option>
                            <option value={2}> Shipped </option>
                            <option value={3}> Delivered </option>
                        </select>
                    </div>
                    <div className="form-control">
                        <button type="submit" disabled={loading} className='filterbtn'>
                            {loading ? "Searching..." : "Search"}
                        </button>
                    </div>
                </form>
            </Modal>

            {
                orderOpen &&
                <Modal
                    isOpen={orderOpen}
                    onRequestClose={closeModal}
                    className={style["modal_styles"]}
                    overlayClassName={style["modal_overlay"]}
                >
                    <h2 className={style.header}>Order Details</h2>
                    <button onClick={closeModal} className={style["close-button"]}><FaTimes size={20} /></button>

                    <div className="registered_blood_bank_container">
                        <table>
                            <thead className='registerd-header-items'>
                                <th>Bag Number</th>
                                <th>Bag SerialNumber</th>
                                <th>Blood Group</th>
                                <th>Blood Component</th>
                            </thead>
                            <tbody>
                                {

                                    orderDetails.length > 0 &&
                                    orderDetails.map((item, index) => {
                                        return (
                                            <tr>
                                                <td>{item.bagNumber}</td>
                                                <td>{item.bagSerialNumber}</td>
                                                <td>{item.bloodGroup}</td>
                                                <td>{item.bloodComponent}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    </div>
                </Modal>
            }


        </div >
    );
}

export default Orders;
