import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import style from './style.module.css'
import { FaTimes } from 'react-icons/fa';
import { BsCloudUpload } from 'react-icons/bs'
import { AddBloodBankService, BloodBanks, BloodBanksTypes, EditBloodBankService, getBloodBank } from '../../Services/APIrequests';
import { Error, SuccessSwal, warningSwal } from '../../Util/Toast';
import { config } from '../../Statusconfig';
import S3FileUpload from "react-s3";
import { FileUploader } from "react-drag-drop-files";
import { State } from 'country-state-city';
import { getAllStates } from 'country-state-city/lib/state';
window.Buffer = window.Buffer || require("buffer").Buffer;

const ReusebleModal = ({ isOpen, setId, onRequestClose, id, fetchData, page, edit, setEdit }) => {
    const [banks, setBanks] = useState([]);
    const [bankTypes, setBankTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [base64, setbase64] = useState("");

    const [country, setCountry] = React.useState('IN');

    const { register, handleSubmit, watch, formState: { errors }, setValue, reset } = useForm();
    // console.log(id)

    const states = getAllStates().filter(state => state.countryCode === country);
    const getBanks = async () => {

        try {
            const res = await BloodBanks()
            if (res?.data?.Status === 1) {
                setBanks(res?.data?.BloodBanks)
            } else {
                Error(res?.data?.Message);
            }
        } catch (err) {
            alert("Something went wrong please try again");
            console.log(err);
        }
    }

    const getBankTypes = async () => {

        try {
            const res = await BloodBanksTypes()
            if (res?.data?.Status === 1) {
                setBankTypes(res?.data?.BloodBankTypes)
            } else {
                Error(res?.data?.Message);
            }
        } catch (err) {
            alert("Something went wrong please try again");
            console.log(err);
        }
    }
    useEffect(() => {
        getBanks();
        getBankTypes();
    }, [])
    const BloodBank = async () => {
        if (id) {
            setEdit(true);
            try {
                const res = await getBloodBank(id)
                if (res?.data?.Status === 1) {
                    reset({
                        bloodBankName: res?.data?.BloodBank.bloodBankName,
                        bloodBankTypeId: res?.data?.BloodBank.bloodBankTypeId,
                        bloodBankEmail: res?.data?.BloodBank.bloodBankEmail,
                        bloodBankMobileNumber: res?.data?.BloodBank.bloodBankMobileNumber,
                        bloodBankAddress: res?.data?.BloodBank.bloodBankAddress,
                        bloodBankCity: res?.data?.BloodBank.bloodBankCity,
                        bloodBankState: res?.data?.BloodBank.bloodBankState,
                        bloodBankPincode: res?.data?.BloodBank.bloodBankPincode,
                        latitude: res?.data?.BloodBank.latitude,
                        longitude: res?.data?.BloodBank.longitude,

                    })
                    setbase64(res?.data?.BloodBank?.bloodBankPhotoPreview);
                }
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        console.log('id', id)
        BloodBank();
    }, [id]);

    function filehandleChange(files) {
        console.log(files.type);
        if (
            files.type === "image/png" ||
            files.type === "image/jpeg" ||
            files.type === "image/webp"
        ) {
            const timestamp = Date.now();
            const newfile = new File(
                [files],
                `${timestamp}.${String(files.type).split("/")[1]}`
            );

            S3FileUpload.uploadFile(newfile, {
                ...config,
                dirName: "BloodBankPhotos",
            })
                .then((res) => {
                    setbase64(res.location);
                    setValue(
                        "bloodBankPhotoId",
                        `${timestamp}.${String(files.type).split("/")[1]}`
                    );
                })
                .catch((err) => {
                    alert(err);
                    console.log(err);
                });
        } else {
            alert(`${files.name} is neither PNG orjpeg`);
            return false;
        }
        return true;
    }

    const onSubmit = async (data) => {
        console.log(data);
        let formData = { ...data }
        if (!formData?.branchOf) {
            delete formData.branchOf
        }
        setLoading(true);

        if (edit) {
            try {
                const res = await EditBloodBankService(id, formData);
                setLoading(false)
                if (res?.data?.Status === 1) {
                    SuccessSwal("Success", res?.data?.Message);
                    fetchData(page);
                    onRequestClose();

                } else {
                    warningSwal("Erorr", res?.data?.Message);
                }
            } catch (err) {
                setLoading(false);
                console.log(err);
                warningSwal("Warning", err?.response?.data?.Message);
            }
        } else {
            setEdit(false);
            try {
                const res = await AddBloodBankService(formData);
                setLoading(false)
                if (res?.data?.Status === 1) {
                    SuccessSwal("Success", res?.data?.Message);
                    fetchData(page);
                    onRequestClose();
                } else {
                    warningSwal("Erorr", res?.data?.Message);
                }
            } catch (err) {
                setLoading(false);
                console.log(err);
                warningSwal("Warning", err?.response?.data?.Message);
            }
        }

    };
    console.log(id)
    const fileuploadDesign = (
        <div className={style["add-item"]}>
            <BsCloudUpload className={style["upload-icon"]} />
            <h5>Drag Your Files here</h5>
            <p>(Only *.jpeg ,*.png will be accepted)</p>
        </div>
    );

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={style.modal_styles}
            overlayClassName={style.modal_overlay}
            contentLabel="BloodBank Form"
        >
            <h2 className={style.header}>{edit ? "Update BloodBank" : "Add BloodBank"}</h2>
            <button onClick={onRequestClose} className={style["close-button"]}><FaTimes size={20} /></button>
            <form onSubmit={handleSubmit(onSubmit)} className={style["modal-form"]}>
                <div className={style.divider}>
                    <label>
                        Name:
                        <input {...register('bloodBankName', {
                            required: edit ? false : 'BloodBank Name is required',
                            pattern: {
                                value: /^[A-Za-z\s]+$/,
                                message: 'BloodBank Name can only contain letters'
                            },
                            minLength: {
                                value: 3,
                                message: 'BloodBank Name must be at least 3 characters long'
                            }

                        })} />
                        {errors.bloodBankName && <p className={style["error"]}>{errors.bloodBankName.message}</p>}
                    </label>
                    <label>
                        Bank TypeId:
                        <select {...register('bloodBankTypeId', { required: edit ? false : 'BloodBank Type is required' })}>
                            <option value=""> Bank Type</option>
                            {bankTypes.length > 0 && bankTypes.map(type => (
                                <option key={type.bloodBankTypeId} value={type.bloodBankTypeId}>{type.bloodBankType}</option>
                            ))}
                        </select>
                        {errors.bloodBankTypeId && <p className={style["error"]}>{errors.bloodBankTypeId.message}</p>}
                    </label>
                </div>
                <div className={style.divider}>
                    <label>
                        Email:
                        <input {...register('bloodBankEmail', {
                            required: edit ? false : 'BloodBank Email is required',
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: 'Invalid email address'
                            }
                        })} />
                        {errors.bloodBankEmail && <p className={style["error"]}>{errors.bloodBankEmail.message}</p>}
                    </label>
                    <label>
                        Contact Number:
                        <input
                            placeholder='ex: 040-6475698 or 9977554466'
                            {...register('bloodBankMobileNumber', {
                                required: edit ? false : 'BloodBank Conatct Number is required',
                                pattern: {
                                    value: /^(?:\d{10}|\d{2,4}[-|\s]\d{6,8})$/,
                                    message: 'Invalid Contact number'
                                }
                            })} />
                        {errors.bloodBankMobileNumber && <p className={style["error"]}>{errors.bloodBankMobileNumber.message}</p>}
                    </label>
                </div>
                <label>
                    Address:
                    <input {...register('bloodBankAddress', {
                        required: edit ? false : 'BloodBank Address is required'
                        ,

                        minLength: {
                            value: 3,
                            message: 'Address must be at least 3 characters long'
                        }
                    })} />
                    {errors.bloodBankAddress && <p className={style["error"]}>{errors.bloodBankAddress.message}</p>}
                </label>
                <label>
                    City:
                    <input {...register('bloodBankCity', {
                        required: edit ? false : 'City is required',
                        pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: 'City can only contain letters'
                        },
                        minLength: {
                            value: 3,
                            message: 'City must be at least 3 characters long'
                        }
                    })} />
                    {errors.bloodBankCity && <p className={style["error"]}>{errors.bloodBankCity.message}</p>}
                </label>
                <label>
                    State:

                    <select
                        {...register('bloodBankState', { required: edit ? false : "State is required" })}>
                        <option value="">Select State</option>
                        {states.map((item, index) => (
                            <option key={index} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                    {errors.bloodBankState && <p className={style["error"]}>{errors.bloodBankState.message}</p>}
                </label>

                <label>
                    Pincode:
                    <input {...register('bloodBankPincode', {
                        required: edit ? false : 'Pincode is required',
                        pattern: {
                            value: /^[0-9]{6}$/,
                            message: 'Pincode must be exactly 6 digits'
                        }
                    })} />
                    {errors.bloodBankPincode && <p className={style["error"]}>{errors.bloodBankPincode.message}</p>}
                </label>

                <label>
                    latitude:
                    <input {...register('latitude', { required: edit ? false : 'latitude is required' })} />
                    {errors.latitude && <p className={style["error"]}>{errors.latitude.message}</p>}
                </label>
                <label>
                    longitude:
                    <input {...register('longitude', { required: edit ? false : 'longitude is required' })} />
                    {errors.longitude && <p className={style["error"]}>{errors.longitude.message}</p>}
                </label>

                <div className={style["file-upload-wrapper"]}>
                    <header>
                        <h3>Image upload</h3>
                    </header>
                    <FileUploader
                        classes={style["upload-image"]}
                        multiple={false}
                        handleChange={filehandleChange}
                        name="file"
                        children={fileuploadDesign}
                    // types={fileTypes}
                    />
                </div>

                {base64 && (
                    <div
                        className={style["file-images-wrapper"]}>
                        <div className={style["image"]}>
                            <img
                                src={base64}
                                alt="uploaded-data"
                                onClick={() => {
                                    window.open(base64, "_blank");
                                }}
                            />
                        </div>
                    </div>
                )}
                <button type="submit" className={style["submit-button"]} >Submit</button>
            </form>
        </Modal >
    )
}

export default ReusebleModal