import ApiService from "./config";

// Login
export const LoginService = (body) => {
  return ApiService.post("admin/login", body);
};

// Dashbord
export const DashbordService = () => {
  return ApiService.get("admin/dashboard");
};

// Orders

export const OrdersService = (count, pageSize) => {
  return ApiService.get(`admin/orders/?page=${count}&size=${pageSize}`);
};

export const OrdersByBloodBankId = (id, count, pageSize) => {
  return ApiService.get(
    `admin/orders/?bloodBankId=${id}&page=${count}&size=${pageSize}`
  );
};

// abonded orders
export const AbondenedService = (count, pageSize) => {
  return ApiService.get(
    `admin/abandoned-carts/?page=${count}&size=${pageSize}`
  );
};

// Bank Services

export const BloodBankService = (count, pageSize) => {
  return ApiService.get(`admin/blood-banks/?page=${count}&size=${pageSize}`);
};

export const AddBloodBankService = (data) => {
  return ApiService.post(`admin/add-blood-bank`, data);
};

export const EditBloodBankService = (Id, data) => {
  return ApiService.patch(`admin/blood-banks/${Id}/edit`, data);
};
export const getBloodBank = (id) => {
  return ApiService.get(`admin/blood-banks/${id}`);
};

//All Blood abnks

export const BloodBanks = () => {
  return ApiService.get(`admin/blood-banks/all`);
};

export const BloodBanksTypes = () => {
  return ApiService.get(`admin/blood-bank-types/all`);
};

// Users

export const UsersService = (bloodBankId, page, count) => {
  return ApiService.get(
    `admin/blood-banks/${bloodBankId}/users?page=${page}&size=${count}`
  );
};

export const AddUserService = (data) => {
  return ApiService.post("admin/add-user", data);
};

export const UpdateUserService = (data, id) => {
  return ApiService.patch(`admin/edit-user/${id}`, data);
};

export const UserService = (id) => {
  return ApiService.get(`admin/users/${id}`);
};

// Filter Apis

export const BloodBankFilter = (number, count, Id) => {
  return ApiService.get(
    `admin/blood-banks/?page=${number}&size=${count}&bloodBankTypeId=${Id}`
  );
};

export const OrdersFilter = (number, count, status) => {
  return ApiService.get(
    `admin/orders/?page=${number}&size=${count}&orderStatus=${status}`
  );
};

export const AdminRoles = () => {
  return ApiService.get(`admin/roles`);
};

export const UsersFilter = (id, roleId, number, count) => {
  return ApiService.get(
    `admin/blood-banks/${id}/users?roleId=${roleId}&page=${number}&size=${count}`
  );
};

// Bllood status for  BankService
export const BloodBankStatus = (bloodBankId, status) => {
  return ApiService.patch(`admin/blood-banks/${bloodBankId}/status/${status}`);
};

export const getOrder = (orderId) => {
  return ApiService.get(`admin/orders/details/${orderId}`);
};
