import React from "react";
import RingLoader from "react-spinners/RingLoader";
import LoadClasses from "./modal.module.css";
function Loader() {
    return (
        <div className={LoadClasses["Loader-wrapper"]}>
            <div className={LoadClasses["loader"]}>
                <RingLoader
                    color="#ed334d"
                    height={100}
                    width={100}
                />
            </div>
        </div>
    );
}

export default Loader;