import ReusebleModal from "../Components/Modal/ReusebleModal";
import Abandoned_cart from "../Pages/Admin/Abandoned_cart";
import Dashboard from "../Pages/Admin/Dashboard";
import Orders from "../Pages/Admin/Orders";
import Registered_blood_bank from "../Pages/Admin/Registered_blood_bank";
import Users from "../Pages/Admin/Users";

export const Config = [
    {
        path: "/dashboard",
        Element: Dashboard
    },
    {
        path: "/registered_blood_bank",
        Element: Registered_blood_bank
    },
    {
        path: "/orders",
        Element: Orders
    },
    {
        path: "/users",
        Element: Users
    },
    {
        path: "/abandoned_cart",
        Element: Abandoned_cart
    },
    {
        path: "/AddBloodbank",
        Element: ReusebleModal
    }
]

