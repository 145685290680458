import React, { useState } from 'react';
import "./Admin.css";
import { IoIosArrowRoundBack } from 'react-icons/io';
import ReactPaginate from 'react-paginate';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdDeleteOutline } from "react-icons/md";
import { FaFilter } from "react-icons/fa";
import { useNavigate} from "react-router-dom";

const orderData = [
    { id: 1, userName: "John Doe",  bloodType: "AB-",product: "whole blood", email: "john@example.com", contactNumber: "123-456-7890" },
    { id: 2, userName: "Jane Smith",  bloodType: "AB-",product: "whole blood", email: "jane@example.com", contactNumber: "123-456-7891" },
    { id: 3, userName: "Alice Johnson",  bloodType: "AB-",product: "whole blood", email: "alice@example.com", contactNumber: "123-456-7892" },
    { id: 4, userName: "John Doe",  bloodType: "AB-",product: "whole blood", email: "john@example.com", contactNumber: "123-456-7890" },
    { id: 5, userName: "Jane Smith",  bloodType: "AB-",product: "whole blood", email: "jane@example.com", contactNumber: "123-456-7891" },
    { id: 6, userName: "Alice Johnson",  bloodType: "AB-",product: "whole blood", email: "alice@example.com", contactNumber: "123-456-7892" },
    { id: 7, userName: "John Doe",  bloodType: "AB-",product: "whole blood", email: "john@example.com", contactNumber: "123-456-7890" },
    { id: 8, userName: "Jane Smith",  bloodType: "AB-",product: "whole blood", email: "jane@example.com", contactNumber: "123-456-7891" },
    { id: 9, userName: "Alice Johnson",  bloodType: "AB-",product: "whole blood", email: "alice@example.com", contactNumber: "123-456-7892" },
    { id: 10, userName: "John Doe",  bloodType: "AB-",product: "whole blood", email: "john@example.com", contactNumber: "123-456-7890" },
    { id: 11, userName: "Jane Smith",  bloodType: "AB-",product: "whole blood", email: "jane@example.com", contactNumber: "123-456-7891" },
    { id: 12, userName: "Alice Johnson",  bloodType: "AB-",product: "whole blood", email: "alice@example.com", contactNumber: "123-456-7892" },
    { id: 13, userName: "John Doe",  bloodType: "AB-",product: "whole blood", email: "john@example.com", contactNumber: "123-456-7890" },
    { id: 14, userName: "Jane Smith",  bloodType: "AB-",product: "whole blood", email: "jane@example.com", contactNumber: "123-456-7891" },
    { id: 15, userName: "Alice Johnson",  bloodType: "AB-",product: "whole blood", email: "alice@example.com", contactNumber: "123-456-7892" },
   
];

function Abandoned_cart() {

   const Navigate = useNavigate();

    const  Users= ()=>{
      Navigate("/Users");
    }


    const [pageNumber, setPageNumber] = useState(0);
    const dataPerPage = 8;

    const pagesVisited = pageNumber * dataPerPage;
    const displayData = orderData.slice(pagesVisited, pagesVisited + dataPerPage);

    const pageCount = Math.ceil(orderData.length / dataPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    return (
        <div className="dashboard">
            <div className="dashboard_admin_tab">
                <p className='dashboard_admin_heading'>Admin Portal</p>
            </div>
            <div className='Registered_blood_bank'>
                <div className="Registered_blood_header">
                    <div className='Resistered_blood_header_text'><IoIosArrowRoundBack  onClick={()=>  Users()} className='registered-icon' />Abandoned Cart</div>
                    <div className='Resistered_blood_header_button'><button className='registered-filter-button'><FaFilter />Filter</button></div>
                </div>
                <div className="registered_blood_bank_container">
                    <table>
                        <thead className="registerd-header-items">
                            <tr>
                                <th>UserName</th>
                                <th>OrderDetails</th>
                                <th>Email Address</th>
                                <th>Contact Number</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayData.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.userName}</td>
                                    <td>
                                       Blood Type : {item.bloodType}
                                       <br />
                                          <span className="product">{item.product}</span>
                                       <br/>
                                    </td>
                                    <td>{item.email}</td>
                                    <td  className="registerd_contact">{item.contactNumber}</td>
                                    <td>
                                        <span><MdDeleteOutline className='regsiterd_delete_icon' /></span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <ReactPaginate
                        previousLabel={<MdOutlineKeyboardArrowLeft />}
                        nextLabel={<MdOutlineKeyboardArrowRight />}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationButtons"}
                        previousLinkClassName={"previousButton"}
                        nextLinkClassName={"nextButton"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                    />
                </div>
            </div>
        </div>
    );
}

export default Abandoned_cart;
