import React, { useState } from 'react';
import "./Admin.css";
import { IoIosArrowRoundBack } from 'react-icons/io';
import ReactPaginate from 'react-paginate';
import { MdDeleteOutline } from "react-icons/md";
import { FaEdit, FaFilter, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BloodBankFilter, BloodBankService, BloodBankStatus, BloodBanksTypes } from '../../Services/APIrequests';
import { useEffect } from 'react';
import { SuccessSwal, warningSwal } from '../../Util/Toast';
import ReusebleModal from '../../Components/Modal/ReusebleModal';
import Loader from '../../Util/Loader';
import Modal from 'react-modal';
import { Switch } from '@mui/material';

function Registered_blood_bank() {
    const [itemOffset, setItemOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState([]);
    const [id, setId] = useState(null);
    const [filterOpen, setFilterOpen] = useState(false);
    const Navigate = useNavigate();
    const [bankTypes, setBankTypes] = useState([]);
    const [edit, setEdit] = useState(false);
    const [bankType, setBankType] = useState(null);
    const [error, setError] = useState("");
    const [pagination, setPagination] = useState({
        page: 1,
        size: 9,
        totalCount: 0
    });
    const dataPerPage = 9;
    const getBankTypes = async () => {

        try {
            const res = await BloodBanksTypes()
            if (res?.data?.Status === 1) {
                setBankTypes(res?.data?.BloodBankTypes)
            } else {
                Error(res?.data?.Message);
            }
        } catch (err) {
            alert("Something went wrong please try again");
            console.log(err);
        }
    }
    useEffect(() => {
        setError("");
    }, [bankType])
    const FilterData = async (page) => {

        if (bankType) {
            setLoading(true);
            try {
                const res = await BloodBankFilter(page, dataPerPage, bankType);
                if (res?.data?.Status === 1) {
                    setData(res?.data?.BloodBanks);
                    setPagination({
                        page: page,
                        size: dataPerPage,
                        totalCount: res?.data?.Pagination.totalCount
                    });
                } else {
                    warningSwal("error", res?.data?.Message);
                }
            } catch (err) {
                warningSwal("Warning", err?.response?.data?.Message);
                console.log(err);
            } finally {
                setLoading(false);
                setFilterOpen(false);
            }
        } else {
            setError("Please select a BloodBank type");
        }
    };

    const fetchData = async (page) => {
        setLoading(true);
        try {
            const res = await BloodBankService(page, dataPerPage)
            setTimeout(() => {
                setLoading(false);
            }, 1500)
            if (res?.data?.Status === 1) {
                setData(res?.data?.BloodBanks);
                setPagination({
                    page: page,
                    size: dataPerPage,
                    totalCount: res?.data?.Pagination.totalCount
                });


            } else {
                warningSwal("error", res?.data?.Message);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    useEffect(() => {
        getBankTypes();
        fetchData(1);
    }, [])

    const Dashboard = () => {
        Navigate("/dashboard");
    }

    const pageCount = Math.ceil(pagination.totalCount / dataPerPage);
    const handlePageClick = (event) => {
        const newPage = event.selected + 1;
        setItemOffset((newPage - 1) * dataPerPage);
        setPagination((prevState) => ({ ...prevState, page: newPage }));
        if (bankType) {
            FilterData(newPage);
        } else {
            fetchData(newPage);
        }
        window.scrollTo({ top: 120, behavior: "smooth" });
    };

    const openModal = () => {
        setIsOpen(true);
        setEdit(false);
    }
    const closeModal = () => {
        setIsOpen(false);
        setId(null);
    }

    const BloodBankusers = (e, bankId) => {
        e.preventDefault();
        e.stopPropagation();
        console.log(bankId)
        Navigate(`/users`, { state: bankId });
    }

    const handleFilterSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        FilterData(1);
    };

    const UpdateBank = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let id = e.currentTarget.getAttribute("id");
        setIsOpen(true);
        setEdit(true)
        setId(id);
    }

    const UpdateStatus = async (e, id, currentStatus) => {
        e.preventDefault();
        e.stopPropagation();
        let newStatus = currentStatus == 1 ? 0 : 1;
        try {
            const res = await BloodBankStatus(id, newStatus);
            if (res?.data?.Status === 1) {
                SuccessSwal("Status updated", res?.data?.Message);
                fetchData(1);
            }
        } catch (err) {
            console.log(err);
            warningSwal("Warning", err?.response?.data?.Message)
        }
    }

    return (
        <div className="dashboard">
            <div className="dashboard_admin_tab">
                <p className='dashboard_admin_heading'>Admin Portal</p>
            </div>
            {loading ? <Loader /> :
                <div className='Registered_blood_bank'>
                    <div className="Registered_blood_header">
                        <div className='Resistered_blood_header_text'><IoIosArrowRoundBack onClick={() => Dashboard()} className='registered-icon' />Registered Blood Bank</div>
                        <div className='Resistered_blood_header_button'>
                            <button className='registered-filter-button' onClick={() => openModal()}>Add</button>
                            <button className='registered-filter-button' onClick={() => setFilterOpen(true)}><FaFilter />Filter</button>
                        </div>
                    </div>

                    <div className='registered_blood_bank_container' style={{ overflowX: 'auto' }} >
                        <table>
                            <thead className='registerd-header-items'>
                                <tr>
                                    <th>Blood Bank Name</th>
                                    <th>BloodBank Type</th>
                                    <th>Address</th>
                                    <th>Contact Number</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item) => (
                                    <tr key={item.bloodBankId} onClick={(e) => BloodBankusers(e, item.bloodBankId)}>
                                        <td>{item.bloodBankName}</td>
                                        <td>{item.bloodBankType}</td>
                                        <td>{item.bloodBankAddress}</td>
                                        <td>{item.bloodBankMobileNumber}</td>
                                        <td
                                        >
                                            <Switch onClick={(e) => e.stopPropagation()} checked={item.bloodBankStatus === 1} onChange={(e) => UpdateStatus(e, item.bloodBankId, item.bloodBankStatus)} color="warning" />
                                        </td>
                                        <td>
                                            <div className="table_btns">
                                                <button type='button'
                                                    className='edit_btn'
                                                    id={item.bloodBankId}
                                                    onClick={UpdateBank}><FaEdit size={20} /></button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="<"
                            forcePage={pagination.page - 1}
                            renderOnZeroPageCount={null}
                            pageClassName={"pageClassName"}
                            disabledLinkClassName={"disabledLinkClassName"}
                            disabledClassName={"paginationDisabled"}
                            containerClassName={"paginationButtons"}
                            nextClassName={"nextButton"}
                            previousClassName={"previousButton"}
                            activeClassName={"activeClassName"}
                            activeLinkClassName={"activeLinkClassName"}
                            pageLinkClassName={"pageLinkClassName"}
                        />
                    </div>
                </div>
            }
            {isOpen &&

                <ReusebleModal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    id={edit ? id : null}
                    fetchData={fetchData}
                    page={pagination.page}
                    edit={edit}
                    setEdit={setEdit}
                    setId={setId}
                />
            }

            <Modal
                isOpen={filterOpen}
                className="modal_class"
                overlayClassName="modal_overlay"
                onRequestClose={() => setFilterOpen(false)}
            >
                <div className='hedaer_group'>
                    <h2 className="modal_header">Blood Bank Filter</h2>
                    <button onClick={() => setFilterOpen(false)} className="close-btn"><FaTimes size={20} /></button>
                </div>
                <form className="filters" onSubmit={handleFilterSubmit}>
                    <div className="form-control">
                        <select
                            id="bloodComponent"
                            value={bankType}
                            onChange={(e) => setBankType(e.target.value)}
                        >
                            <option value="">Select Blood Bank type</option>
                            {bankTypes.map((type) => (
                                <option
                                    key={type.bloodBankTypeId}
                                    value={type.bloodBankTypeId}
                                >
                                    {type.bloodBankType}
                                </option>
                            ))}

                        </select>
                        {error.length ? <span className="errorspan">{error}</span> : ""}
                    </div>
                    <div className="form-control">
                        <button type="submit" disabled={loading} className='filterbtn'>
                            {loading ? "Filtering..." : "Filter"}
                        </button>
                    </div>
                </form>
            </Modal>

        </div>
    );
}

export default Registered_blood_bank;
