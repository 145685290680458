import React, { useState } from 'react';
import "./Admin.css";
import { IoIosArrowRoundBack } from 'react-icons/io';
import ReactPaginate from 'react-paginate';
import { MdDeleteOutline } from "react-icons/md";
import { FaEdit, FaFilter, FaTimes } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from 'react';
import { AddUserService, AdminRoles, BloodBanks, UpdateUserService, UserService, UsersFilter, UsersService } from '../../Services/APIrequests';
import { SuccessSwal, warningSwal } from '../../Util/Toast';
import Modal from 'react-modal';
import style from '../../Components/Modal/style.module.css'
import { useForm } from 'react-hook-form';
import Loader from '../../Util/Loader';

function Users() {
    const [itemOffset, setItemOffset] = useState(0);
    const [banks, setBanks] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const bankId = location.state || {};
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [userData, setUserData] = useState({});
    const Navigate = useNavigate();
    const [editable, setEditable] = useState(false);
    const [userId, setUserId] = useState(null);
    const [roles, setRoles] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);
    const [roleId, setRoleId] = useState();
    const [error, setError] = useState("");
    const [bankaNme, setBankaNme] = useState("");
    const [pagination, setPagination] = useState({
        page: 1,
        size: 9,
        totalCount: 0
    });
    const { register,
        handleSubmit,
        watch,
        formState: { errors },
        reset, getValues } = useForm({
            defaultValues: { bloodBankId: bankId }
        });
    const dataPerPage = 9;

    console.log(bankId)
    console.log(getValues());

    const getBanks = async () => {

        try {
            const res = await BloodBanks()
            if (res?.data?.Status === 1) {
                setBanks(res?.data?.BloodBanks)
            } else {
                Error(res?.data?.Message);
            }
        } catch (err) {
            alert("Something went wrong please try again");
            console.log(err);
        }
    }

    const getRoles = async () => {

        try {
            const res = await AdminRoles()
            if (res?.data?.Status === 1) {
                setRoles(res?.data?.Roles)
            } else {
                Error(res?.data?.Message);
            }
        } catch (err) {
            alert("Something went wrong please try again");
            console.log(err);
        }
    }
    useEffect(() => {
        setError("");
    }, [roleId])

    const FilterData = async (page) => {
        if (roleId) {
            setLoading(true);
            try {
                const res = await UsersFilter(bankId, roleId, page, dataPerPage,);
                if (res?.data?.Status === 1) {
                    setData(res?.data?.Users);
                    setPagination({
                        page: page,
                        size: dataPerPage,
                        totalCount: res?.data?.Pagination.totalCount
                    });
                } else {
                    warningSwal("error", res?.data?.Message);
                }
            } catch (err) {
                alert("Something went wrong, please try again");
                console.log(err);
            } finally {
                setLoading(false);
                setFilterOpen(false);
            }
        }
        else {
            setError("Please select a Role");
        }
    };

    const fetchData = async (page) => {
        setLoading(true);
        try {
            const res = await UsersService(bankId, page, dataPerPage)
            setTimeout(() => {
                setLoading(false);
            }, 1500)
            if (res?.data?.Status === 1) {
                setData(res?.data?.Users);
                setPagination({
                    page: page,
                    size: dataPerPage,
                    totalCount: res?.data?.Pagination.totalCount
                });
                setBankaNme(res?.data?.BloodBankName);
            } else {
                warningSwal("error", res?.data?.Message);
            }
        } catch (err) {
            console.log(err);
            // setLoading(false);

        }
    }
    useEffect(() => {
        getRoles();
    }, [])
    useEffect(() => {
        getBanks();
        fetchData(1);
    }, [])

    const Orders = () => {
        Navigate(-1);
    }

    const pageCount = Math.ceil(pagination.totalCount / dataPerPage);
    const handlePageClick = (event) => {
        console.log(event.target)
        const newPage = event.selected + 1;
        setItemOffset((newPage - 1) * dataPerPage);
        setPagination((prevState) => ({ ...prevState, page: newPage }));
        if (roleId) {
            FilterData(newPage);
        } else {
            fetchData(newPage);
        }
        window.scrollTo({ top: 120, behavior: "smooth" });
    };
    const User = async (id) => {
        if (id) {
            setUserId(id);
            setIsOpen(true);
            setEditable(true);
            setLoading(true)
            try {
                const res = await UserService(id);
                setTimeout(() => {
                    setLoading(false);
                }, 1500)
                if (res?.data?.Status === 1) {
                    setUserData(res?.data?.User);
                    reset({
                        userName: res?.data?.User.userName,
                        roleId: res?.data?.User.roleId,
                        email: res?.data?.User.email,
                        bloodBankId: res?.data?.BloodBankId,
                        mobileNumber: res?.data?.User.mobileNumber,
                        city: res?.data?.User.city,
                    })

                } else {
                    warningSwal("error", res?.data?.Message);
                }
            } catch (err) {
                console.log(err);

            }
        }
    }


    const openModal = () => {
        setIsOpen(true);
        setEditable(false);
        setUserId(null);
        setUserData({});

    }
    const closeModal = () => {
        setIsOpen(false);
        reset({
            userName: '',
            roleId: '',
            email: '',
            mobileNumber: '',
            city: '',
            bloodBankId: bankId,
            password: ''
        });
    }



    const onSubmit = async (data) => {
        console.log(data);

        let formData = { ...data }
        if (!formData?.password) {
            delete formData.password
        }
        if (editable) {
            setLoading(true)
            try {
                const res = await UpdateUserService(formData, userId);
                setTimeout(() => {
                    setLoading(false);
                }, 2000)
                if (res?.data?.Status === 1) {
                    SuccessSwal("success", res?.data?.Message);
                    fetchData(1);
                    closeModal();
                } else {
                    warningSwal("Erorr", res?.data?.Message);
                }
            } catch (err) {
                console.log(err);
                setLoading(false);
                warningSwal("warning", err?.response?.data?.Message);
            }

        } else {
            setEditable(false);
            setLoading(true)
            try {
                const res = await AddUserService(formData);
                setTimeout(() => {
                    setLoading(false);
                }, 2000)
                if (res?.data?.Status === 1) {
                    SuccessSwal("Success", res?.data?.Message);
                    fetchData(1);
                    closeModal();
                } else {
                    warningSwal("Erorr", res?.data?.Message);
                }
            } catch (err) {
                console.log(err);
                setLoading(false);
                warningSwal("Erorr", err?.response?.data?.Message);
            }
        }
        console.log(data);
    }
    const handleFilterSubmit = (event) => {
        event.preventDefault();
        FilterData(1);
    };

    return (
        <div className="dashboard">
            <div className="dashboard_admin_tab">
                <p className='dashboard_admin_heading'>Admin Portal </p>
            </div>

            <div className='Registered_blood_bank'>
                <div className="Registered_blood_header">
                    <div className='Resistered_blood_header_text'><IoIosArrowRoundBack onClick={() => Orders()} className='registered-icon' />{bankaNme} {" "}Users</div>
                    <div className='Resistered_blood_header_button'>
                        <button className='registered-filter-button' onClick={() => openModal()}>Add</button>
                        <button className='registered-filter-button' onClick={() => setFilterOpen(true)}><FaFilter />Filter</button>
                    </div>
                </div>
                {
                    loading ? <Loader /> :

                        <div className="registered_blood_bank_container">
                            <table>
                                <thead className="registerd-header-items">
                                    <tr>
                                        <th>Username</th>
                                        <th>Role</th>
                                        <th>Email Address</th>
                                        <th>Contact Number</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length === 0 ? <tr>
                                        <td colSpan={5}>No Users here</td>
                                    </tr> :
                                        data.map((item, id) => (
                                            <tr key={id}>
                                                <td>{item.userName}</td>
                                                <td>
                                                    {item.role === 'Manager' ?
                                                        <div
                                                            className={
                                                                "status-booked"
                                                            }>
                                                            <span>Manager</span>
                                                        </div> :
                                                        item.role === 'Assistant' ?
                                                            <div
                                                                className={
                                                                    "status-issued"
                                                                }>
                                                                <span>Assistant</span>
                                                            </div> :
                                                            item.role === 'Admin' ?
                                                                <div
                                                                    className={
                                                                        "status-delivered"
                                                                    }>
                                                                    <span>Admin</span>
                                                                </div> : ""
                                                    }
                                                </td>
                                                <td>{item.email}</td>
                                                <td className="registerd_contact">{item.mobileNumber === " " ? "NA" : item.mobileNumber}</td>
                                                <td>
                                                    <div className="table_btns">
                                                        <button type='button' className='edit_btn' onClick={() => User(item.userId)}><FaEdit size={20} /></button>
                                                        {/* <button type='button' className='delete_btn'><MdDeleteOutline size={20} /></button> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="<"
                                forcePage={pagination.page - 1}
                                renderOnZeroPageCount={null}
                                pageClassName={"pageClassName"}
                                disabledLinkClassName={"disabledLinkClassName"}
                                disabledClassName={"paginationDisabled"}
                                containerClassName={"paginationButtons"}
                                nextClassName={"nextButton"}
                                previousClassName={"previousButton"}
                                activeClassName={"activeClassName"}
                                activeLinkClassName={"activeLinkClassName"}
                                pageLinkClassName={"pageLinkClassName"}
                            />
                        </div>
                }
            </div>

            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                className={style["modal_styles"]}
                overlayClassName={style["modal_overlay"]}
            >
                <h2 className={style.header}>{editable ? "Update User" : "Add User"}</h2>
                <button onClick={closeModal} className={style["close-button"]}><FaTimes size={20} /></button>
                <form onSubmit={handleSubmit(onSubmit)} className={style["modal-form"]}>
                    <div className={style.divider}>

                        <label>
                            UserName:
                            <input {...register('userName', {
                                required: editable ? false : 'User Name is required',
                                pattern: {
                                    value: /^[A-Za-z\s]+$/,
                                    message: 'User Name must be in Charectors'
                                },
                                minLength: {
                                    value: 3,
                                    message: 'User Name must be at least 3 characters long'
                                }
                            })} />
                            {errors.userName && <p className={style["error"]}>{errors.userName.message}</p>}
                        </label>
                    </div>
                    <div className={style.divider}>
                        <label>
                            Email:
                            <input {...register('email', {
                                required: editable ? false : 'Email is required',
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: 'Invalid email address'
                                }
                            })} />
                            {errors.email && <p className={style["error"]}>{errors.email.message}</p>}
                        </label>
                        <label>
                            Password:
                            <input {...register('password', {
                                required: editable ? false : 'Password is required',
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])(.*){8,}$/,
                                    message:
                                        "Password must include uppercase, lowercase, number, and special character!",
                                },
                            }

                            )} />
                            {errors.password && <p className={style["error"]}>{errors.password.message}</p>}
                        </label>
                    </div>
                    <label>
                        Contact Number:
                        <input {...register('mobileNumber', {
                            required: editable ? false : 'Mobile Number is required',
                            pattern: {
                                value: /^[0-9]{10}$/,
                                message: 'MobileNumber must be 10 digits'
                            }
                        })} />
                        {errors.mobileNumber && <p className={style["error"]}>{errors.mobileNumber.message}</p>}
                    </label>
                    <div className={style.divider}>
                        {/* {!editable && (<label>
                            BloodBank Id:
                            <select {...register('bloodBankId', { required: "BloodBank is Required" })} disabled>
                                <option defaultValue={bankId}>Select BloodBank</option>
                                {banks.length > 0 && banks.map(branch => (
                                    <option key={branch.bloodBankId} value={branch.bloodBankId}>{branch.bloodBankName}</option>
                                ))}
                            </select>
                            {errors.bloodBankId && <p className={style["error"]}>{errors.bloodBankId.message}</p>}
                        </label>)} */}
                        <label>
                            Role:
                            <select {...register('roleId', { required: editable ? false : 'Role is required' })}>
                                <option value="">Select Role</option>
                                {roles.length > 0 && roles.map(branch => (
                                    <option key={branch.roleId} value={branch.roleId}>{branch.role}</option>
                                ))}
                            </select>
                            {errors.roleId && <p className={style["error"]}>{errors.roleId.message}</p>}
                        </label>
                    </div>
                    <label>
                        City:
                        <input {...register('city', {
                            required: editable ? false : 'City is required',
                            pattern: {
                                value: /^[A-Za-z\s]+$/,

                                message: 'City must be in charectors'
                            },
                            minLength: {
                                value: 3,
                                message: 'City must be at least 3 characters long'
                            }
                        })} />
                        {errors.city && <p className={style["error"]}>{errors.city.message}</p>}
                    </label>
                    <button type="submit" className={style["submit-button"]} >Submit</button>
                </form>
            </Modal>

            <Modal
                isOpen={filterOpen}
                className="modal_class"
                overlayClassName="modal_overlay"
                onRequestClose={() => setFilterOpen(false)}
            >
                <div className='hedaer_group'>
                    <h2 className="modal_header">Users Filter</h2>
                    <button onClick={() => setFilterOpen(false)} className="close-btn"><FaTimes size={20} /></button>
                </div>
                <form className="filters" onSubmit={handleFilterSubmit}>
                    <div className="form-control">
                        <select
                            id="bloodComponent"
                            value={roleId}
                            onChange={(e) => setRoleId(e.target.value)}
                        >
                            <option value="">Select Role </option>
                            {roles.map((type) => (
                                <option
                                    key={type.roleId}
                                    value={type.roleId}
                                >
                                    {type.role}
                                </option>
                            ))}
                        </select>
                        {error.length ? <span className='errorspan'>{error}</span> : ""}
                    </div>
                    <div className="form-control">
                        <button type="submit" disabled={loading} className='filterbtn'>
                            {loading ? "Filtering..." : "Filter"}
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
}

export default Users;
