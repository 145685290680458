import React from 'react'
import { Config } from './route_config';
import { Route, Routes } from 'react-router-dom';
import Auth from '../Util/Auth';
import Login from '../Pages/Login/Login';
function Router() {
    return (
        <Routes>
            <Route path='/' element={<Login />} />
            <Route path='' element={<Auth />}>
                {Config.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={<route.Element />}></Route>
                ))}
            </Route>
        </Routes>
    )
}

export default Router;