import React, { useState, useEffect, useLayoutEffect } from "react";
import { LiaCartPlusSolid } from "react-icons/lia";
import { CgBorderAll } from "react-icons/cg";
import { HiOutlineLogout, HiUser } from "react-icons/hi";
import bloodbank from "../../assets/bloodbank1.png";
import { useNavigate } from "react-router-dom";
import "./Admin.css";
import { DashbordService } from "../../Services/APIrequests";
import { Error, SuccessSwal } from "../../Util/Toast";
import moment from "moment/moment";
import swal from "sweetalert";
import Loader from "../../Util/Loader";

function Dashboard() {
  const [data, setData] = useState([]);

  const [orders, setOrders] = useState([]);
  const [orderCount, setOrderCount] = useState();
  const [userCount, setUserCount] = useState();
  const [ceasedCount, setCeasedCount] = useState();
  const [abandonedCartsCount, setAbandonedCartsCount] = useState();
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    if (localStorage.getItem("Token")) {
      Navigate("/dashboard");
    }
  }, []);

  const fetchdata = async () => {
    setLoading(true);
    try {
      const res = await DashbordService()
      setTimeout(() => {
        setLoading(false);
      }, 1500)
      if (res?.data?.Status === 1) {
        setData(res?.data?.BloodBanks);
        setOrders(res?.data?.Orders);
        setUserCount(res?.data?.UserCount);
        setOrderCount(res?.data?.OrderCount);
        setCeasedCount(res?.data?.CeasedCount);
        setAbandonedCartsCount(res?.data?.AbandonedCartsCount);
      }
    } catch (err) {
      console.log(err);
      Error("Something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchdata();
  }, [])

  const Users = () => {
    Navigate("/Users");
  };
  const Orders = () => {
    Navigate("/orders");
  };
  const Registered_blood_bank = () => {
    Navigate("/registered_blood_bank");
  };

  const Adandoned_cart = () => {
    Navigate("/abandoned_cart");
  };

  const logoutHandler = () => {
    swal("Are You sure you want to Sign out?", {
      icon: "warning",
      className: "logout-popup",
      dangerMode: true,
      buttons: {
        cancel: "Cancel",
        catch: {
          text: "Logout",
          value: "Logout",
          className: "cancel",
        },
        defeat: false,
      },
    }).then((value) => {
      switch (value) {
        case "Logout":
          Navigate("/");
          localStorage.clear();
          break;

        default:
        // swal("Got away safely!");
      }
    });
  };

  return (
    <div className="dashboard">
      <div className="dashboard_admin_tab">
        <div className="dashboard_admin_left">
          <p className='dashboard_admin_heading'>Admin Portal</p>
        </div>
        <div className="dashboard_admin_right">
          {/* <button className="logout-button">Logout</button> */}
          <button onClick={logoutHandler} className="logout-button">
            <HiOutlineLogout />Logout
          </button>
        </div>
      </div>
      <div className="dashboard_main_tab">
        <p className="dashboard_heading">Dashboard</p>
        {
          loading ?
            <Loader /> :
            <div className="dashboard_main_container">
              <div className="dash_main_flex1">
                <div className="dash_main_flex1_heading">
                  <p className="dash_main_flex1_heading_left">
                    Registered blood bank
                  </p>
                  <p
                    className="dash_main_flex1_heading_right"
                    onClick={() => Registered_blood_bank()}
                  >
                    View All
                  </p>
                </div>

                {/* <div className="blood_bank_count">
              {" "}
              Lydia Dorwart Blood Bank (3)
            </div> */}

                <div className="blood_bank_details">
                  <div
                    className="table-container"
                  >
                    <table>
                      <tbody>
                        {data.length === 0 ? <tr>
                          <td colSpan={2}>No Blood Banks  Here</td>
                        </tr> :
                          data.map((bank, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <td colSpan="2">
                                  <div className="blood-bank-container">
                                    <div className="image-container">
                                      <img
                                        src={bank.bloodBankPhoto}
                                        alt="Blood Bank"
                                        className="blood-bank-img"
                                      />
                                    </div>
                                    <div className="details-container">
                                      <span className="blood-bank-name">
                                        {bank.bloodBankName}
                                      </span>
                                      <span className="blood-bank-location">
                                        {(bank.bloodBankAddress !== "NA" && bank.bloodBankAddress !== "") ?
                                          <>
                                            {bank.bloodBankAddress}
                                          </> : <> -- </>}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="dash_main_flex2">
                <div className="dash_main_flex2_container1">
                  <div className="dash_orders">
                    <div className="dash-orders-container" onClick={() => Orders()}>
                      <div className="dash-orders-text">
                        <p className="dash-order-top">Orders</p>
                        <p className="dash-order-buttom">New Orders {" "}{orders.length}</p>
                      </div>
                    </div>
                    <div className="dash_icons">
                      <CgBorderAll className="order_icons" />
                    </div>
                  </div>

                  <div className="dash_users">
                    <div className="dash-orders-container">
                      <div className="dash-orders-text">
                        <p className="dash-order-top">Users</p>
                        <p className="dash-order-buttom">Total Users {" "}{userCount}</p>
                      </div>
                    </div>
                    <div className="dash_icons">
                      <HiUser className="order_icons" />
                    </div>
                  </div>

                  {/* <div className="dash_cart">
                <div
                  className="dash-orders-container"
                  onClick={() => Adandoned_cart()}
                >
                  <div className="dash-orders-text">
                    <p className="dash-order-top">Abandoned Cart</p>
                    <p className="dash-order-buttom">Abandoned Cart{" "}{abandonedCartsCount}</p>
                  </div>
                </div>
                <div className="dash_icons">
                  <LiaCartPlusSolid className="order_icons" />
                </div>
              </div> */}
                </div>

                <div className="dash_main_flex2_container2">
                  <div className="dash_main_flex1_heading">
                    <p className="dash_main_flex1_heading_left">New Orders</p>
                    <p
                      className="dash_main_flex1_heading_right"
                      onClick={() => Orders()}
                    >
                      View All
                    </p>
                  </div>

                  <div>
                    <div className="Dashboard-table-container">
                      <div
                        className="table-container"
                      >
                        <table>
                          <thead className="sticky-header">
                            <tr>
                              <th>Patient Name</th>
                              <th>Order Details</th>
                              <th>Ordered Items</th>
                              <th>Order Date</th>
                            </tr>
                          </thead>

                          <tbody className="Dashboard-table-body">
                            {orders.map((item, index) => (
                              <tr key={index}>
                                <td>{item.patientName}</td>
                                <td>
                                  Blood Type : {item.bloodGroup}
                                  <br />
                                  <span className="product">{item.bloodComponent}</span>
                                  <br />
                                </td>
                                <td>{item.units}{" "}Items</td>
                                <td className="order-date">{moment(item.createdAt).format("Do MMM YYYY")}</td>
                              </tr>
                            ))}
                          </tbody>

                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    </div>
  );
}

export default Dashboard;
