import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import login from "./Login.module.css";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { LoginService } from "../../Services/APIrequests";
import ApiService from "../../Services/config";
import { TextField } from "@mui/material";
import { warningSwal } from "../../Util/Toast";
function Login() {
  const Navigate = useNavigate();
  const [hidePassword, setHidePassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useLayoutEffect(() => {
    const token = localStorage.getItem("Token");

    if (token) {
      Navigate("/dashboard");
    }
  }, []);

  const onSubmit = async (data) => {
    console.log(data);
    try {
      const response = await LoginService(data);
      setLoading(false);
      if (response?.data?.Status === 1) {
        ApiService.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response?.data?.Token}`;
        localStorage.setItem("Token", response?.data?.Token);
        Navigate("/dashboard", { replace: true });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      warningSwal("Warning", err?.response?.data?.Message);
    }
  };

  return (
    <div className={login["login"]}>
      <div className={login["login-container"]}>
        <h2 className={login["login-header"]}>Login</h2>
        <form className={login["login-form"]} onSubmit={handleSubmit(onSubmit)}>
          <div className={login["login-form-group"]}>
            <label className={login["login-form-label"]} htmlFor="mobileNumber">
              Email
            </label>
            <div className={login.input_container}>
              <TextField
                id="fullWidth"
                label="Email"
                fullWidth
                type="email"
                placeholder="Type your email"
                {...register("email", {
                  required: "Email is required!",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Enter a valid email address!",
                  },
                })}
              />
            </div>
            {errors?.email && (
              <p className={login.error}>{errors?.email?.message}</p>
            )}
          </div>
          <div className={login["login-form-group"]}>
            <label className={login["login-form-label"]} htmlFor="password">
              Password
            </label>
            <div className={login.input_container}>
              <TextField
                id="fullWidth"
                label="Password"
                fullWidth
                type={hidePassword ? "password" : "text"}
                placeholder="Type your password"
                {...register("password", {
                  required: "Password is required!",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long!",
                  },
                  pattern: {
                    value:
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message:
                      "Password must include uppercase, lowercase, number, and special character!",
                  },
                })}
              />
              <p className={login.picons}>
                {hidePassword ? (
                  <IoEye onClick={() => setHidePassword(false)} />
                ) : (
                  <IoEyeOff onClick={() => setHidePassword(true)} />
                )}
              </p>
            </div>
            {errors?.password && (
              <p className={login.error}>{errors?.password?.message}</p>
            )}
          </div>
          <button className={login["login-form-button"]} type="submit">
            Login
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
